import React, {useState} from 'react';
import {graphql} from "gatsby";
import Header from "../components/Header/header";
import {ContainerL} from "../ui/containers";
import Heading from "../ui/heading";
import Card from "../components/Cards";
import Footer from "../components/Footer/footer";
import Modal from "../ui/modal";
import Seo from "../components/seo";
import "../components/layout.css";
import styled from "styled-components";

const ContainerHeadingL = styled.div`
  max-width: 1280px;
  margin: 80px auto;
  width: 100%;
  @media (max-width: 1268px) {
    margin: 20px auto;
  };
  @media (max-width: 569px) {
    padding: 10px;
  };
`

const Cooperation = ({data}) => {
    const [openModal, setOpenModal] = useState(false);
    const fullData = data.allStrapiJoinWork.nodes[0];
    const {cards} = fullData;

    const cardsData = cards.map(item => {
        return ({
            title: item.title,
            text: item.description,
            imgUrl: item.image.localFile,

        })
    })

    const toggleOpenModal = () => {
        if (!openModal) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
        setOpenModal(!openModal);
    }
    return (
        <>

            <Seo title={fullData.seo_title}
                 description={fullData.seo_description}
                 keywords={fullData.seo_keywords.split(',')}
            />


                <>
                    <Header/>
                    <Modal openModal={openModal} toggleOpenModal={toggleOpenModal}/>
                    <ContainerHeadingL>
                        <Heading
                            title={fullData.title}
                            description={fullData.description}
                            toggleOpenModal={toggleOpenModal}
                            background={"партнеры"}
                        />
                    </ContainerHeadingL>

                    <ContainerL>
                        {cardsData.map((item, index) => (
                            <div key={index}>
                                <Card
                                    plus={false}
                                    cooperation={true}
                                    toggleOpenModal={toggleOpenModal}
                                    isreversed={index % 2 !== 0}
                                    data={item}
                                    button
                                />
                            </div>
                        ))}
                    </ContainerL>
                    <Footer toggleOpenModal={toggleOpenModal}/>
                </>
        </>
    );
}

export default Cooperation;

export const query = graphql`
query CooperationQuery {
allStrapiJoinWork {
nodes {
seo_description
seo_keywords
seo_title
description
title
cards {
image {
localFile {
publicURL
}
}
description
title
}
}
}
}
`